import React, { useContext, useState, useEffect } from "react";
import CountUp from "react-countup";
import LazyLoad from "react-lazyload";
import PageWrapper from "../../components/PageWrapper";
import GlobalContext from "../../context/GlobalContext";
import { getLastSixMonths, addDays } from "../../utils/addDays"
import { AdminUsers } from "../../utils/apiCalls";
import { GetAdminDashboardCount } from "../../utils/GetAPIUtils";

import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';

export default function DashboardMain() {
    const gContext = useContext(GlobalContext);
    const [adminDashboardCount, setAdminDashboardCount] = useState([]);
    const [totalClients, setTotalClients] = useState(null);
    const [totalExperts, setTotalExperts] = useState(null);
    const [totalActiveContracts, setTotalActiveContracts] = useState(null);
    const [totalActiveUsers, setTotalActiveUsers] = useState(null);
    const [totalFlaggedUsers, setTotalFlaggedUsers] = useState(null);
    const [totalJobs, setTotalJobs] = useState(null);
    const [totalOffers, setTotalOffers] = useState(null);
    const [totalProposals, setTotalProposals] = useState(null);
    const [totalERA, setTotalERA] = useState(null);
    const [expertData, setexpertData] = useState([]);
    const [clientData, setclientData] = useState([]);
    const [userCount, setUserCount] = useState([]);
    const [cuserVerifiedCount, setcUserVerifiedCount] = useState([]);
    const [euserVerifiedCount, seteUserVerifiedCount] = useState([]);
    const [userERACount, setUserERACount] = useState([]);
    const [tabValue, setTabValue] = useState('total');
    const xLabels = getLastSixMonths(new Date());
    function getMonthYear(date) {
        const lastSixMonth = new Date(date?.toString()?.split("T")[0]);
        const monthName = lastSixMonth.toLocaleString("default", { month: "short" });
        return `${monthName} ${lastSixMonth.getFullYear()}`;
    }
    async function LoadUsers() {
        try {
            gContext.setLoaderModalVisible(true);
            let expert = [];let client=[];
            const result = await AdminUsers("all", 1);
            if (result.data.adminUsers && result.data.adminUsers.responseData.code == "200") {
                expert = JSON.parse(result.data.adminUsers.responseData.message);

                let completed = expert.filter(item => item["assessmentcomplete"] == true);
                let notcompleted = expert.length - completed.length;
                setTotalERA(completed.length);
                setUserERACount([
                    { id: 0, value: completed.length, label: 'Assessment Completed (' + completed.length + ')' + " - " + Math.round((completed.length / expert.length) * 100) + '%' },
                    { id: 1, value: notcompleted, label: 'Assessment Not Completed (' + notcompleted + ')' + " - " + Math.round((notcompleted / expert.length) * 100) + '%' },

                ]);

            }
            const result1 = await AdminUsers("all", 2);
            if (result1.data.adminUsers && result1.data.adminUsers.responseData.code == "200") {
                client = JSON.parse(result1.data.adminUsers.responseData.message);
            }
            let total = client.length + expert.length;
            setUserCount([
                { id: 0, value: client.length, label: 'Clients (' + client.length + ')' + " - " + Math.round((client.length / total) * 100) + '%' },
                { id: 1, value: expert.length, label: 'Experts (' + expert.length + ')' + " - " + Math.round((expert.length / total) * 100) + '%' },

            ]);
            
            let expertVerified = expert.filter(item => item["profilecomplete"] == true && item["idverified"] == true);
            
            let clientVerified = client.filter(item => item["profilecomplete"] == true); 
            let clientNotverified = client.length - clientVerified.length;
            let expertNotverified = expert.length - expertVerified.length;

            setcUserVerifiedCount([
                { id: 0, value: client.length - clientVerified.length, label: 'Not Verified Client (' + clientNotverified + ')' + " - " + Math.round((clientNotverified / client.length) * 100) + '%' },
                { id: 1, value: clientVerified.length, label: 'Verified Client (' + clientVerified.length + ')' + " - " + Math.round((clientVerified.length / client.length) * 100) + '%' },
            ]);
            seteUserVerifiedCount([                 
                { id: 0, value: expert.length - expertVerified.length, label: 'Not Verified Expert (' + expertNotverified + ')' + " - " + Math.round((expertNotverified / expert.length) * 100) + '%' },
                { id: 1, value: expertVerified.length, label: 'Verified Experts (' + expertVerified.length + ')' + " - " + Math.round((expertVerified.length / expert.length) * 100) + '%' },

            ]);
            let expertValue = [];
            let clientValue = [];

            xLabels.forEach((value) => {
                let sdata = expert.filter(item => getMonthYear(item["datecreated"]) == value);
                expertValue.push(sdata.length);
            });
            setexpertData(expertValue);

            xLabels.forEach((value) => {
                let sdata = client.filter(item => getMonthYear(item["datecreated"]) == value);
                clientValue.push(sdata.length);
            });
            setclientData(clientValue);
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            console.log(e);
        }
    }
    async function getUtils() {

        try {
            gContext.setLoaderModalVisible(true);
            const response = await GetAdminDashboardCount();

            if (response.data && response.data.adminDashboardCount) {

                setAdminDashboardCount(response.data.adminDashboardCount);
                setTotalClients(response.data.adminDashboardCount.totalClients);
                setTotalExperts(response.data.adminDashboardCount.totalExperts);
                setTotalActiveUsers(response.data.adminDashboardCount.totalActiveUsers);
                setTotalActiveContracts(response.data.adminDashboardCount.totalActiveContracts);
                setTotalFlaggedUsers(response.data.adminDashboardCount.totalFlaggedUsers);
                setTotalJobs(response.data.adminDashboardCount.totalJobs);
                setTotalOffers(response.data.adminDashboardCount.totalOffers);
                setTotalProposals(response.data.adminDashboardCount.totalProposals);

                gContext.setLoaderModalVisible(false);
            }
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
        }
        gContext.setLoaderModalVisible(false);
    }

    useEffect(() => {
        document.title = "Dashboard | Syncuppro";
        LoadUsers();
        getUtils();
      

    }, []);
    return (
        <>
            <PageWrapper
                headerConfig={{
                    button: "profile",
                    isFluid: true,
                    bgClass: "bg-default",
                    reveal: false,
                }}
            >

                <div className="dashboard-main-container mt-15 mt-lg-22 min-h-100vh">
                    <div className="container">
                        <div className="row mb-7">

                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-6" >
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-2 pt-3 pb-3 pr-2 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-blue bg-blue-opacity-1 circle-40 font-size-6 mr-7">
                                        <i className="fas fa-user"></i>
                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-7 text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span>
                                                {(totalClients) ? totalClients : 0}
                                            </span>

                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Clients
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-2 pt-3 pb-3 pr-2 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-pink bg-pink-opacity-1 circle-40 font-size-6 mr-7">
                                        <i className="fas fa-user-tie">  </i>
                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-7 text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span>
                                                {(totalExperts) ? totalExperts : 0}
                                            </span>

                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Experts

                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-2 pt-3 pb-3 pr-2 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-red bg-red-opacity-1 circle-40 font-size-6 mr-7">
                                        <i className="fas fa-flag"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-7 text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span>
                                                {(totalFlaggedUsers) ? totalFlaggedUsers : 0}
                                            </span>


                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Flagged
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-2 pt-3 pb-3 pr-2 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-green bg-green-opacity-1 circle-40 font-size-6 mr-7">
                                        <i className="fas fa-briefcase"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-7 text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span>
                                                {(totalJobs) ? totalJobs : 0}
                                            </span>


                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Jobs
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>


                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-2 pt-3 pb-3 pr-2 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-orange bg-orange-opacity-1 circle-40 font-size-6 mr-7">
                                        <i className="fas fa-file-contract"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-7 text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span>
                                                {(totalActiveContracts) ? totalActiveContracts : 0}
                                            </span>

                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Active Contracts
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-2 pt-3 pb-3 pr-2 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-yellow bg-yellow-opacity-1 circle-40 font-size-6 mr-7">
                                        <i className="fas fa-users"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-7 text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span>
                                                {(totalActiveUsers) ? totalActiveUsers : 0}
                                            </span>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Verified Users
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>

                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-2 pt-3 pb-3 pr-2 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-blue bg-blue-opacity-1 circle-40 font-size-6 mr-7">
                                        <i className="fas fa-briefcase"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-7 text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span>
                                                {(totalProposals) ? totalProposals : 0}
                                            </span>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Proposals
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>
                            <div className="col-xxl-3 col-xl-3 col-lg-6 col-sm-6">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-2 pt-3 pb-3 pr-2 hover-shadow-1 mb-9 shadow-8"
                                >
                                    <div className="text-green bg-green-opacity-1 circle-40 font-size-6 mr-7">
                                        <i className="fas fa-file-contract"></i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-7 text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span>
                                                {(totalOffers) ? totalOffers : 0}
                                            </span>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            Offers
                                        </p>
                                    </div>
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>

                            <div className="col-xxl-3 col-xl-6 col-lg-6 col-sm-12">
                                {/* <!-- Single Category --> */}
                                <a

                                    className="media bg-white rounded-4 pl-2 pt-3 pb-3 pr-2 hover-shadow-1 mb-9 shadow-8"
                                >
                                   <div className="text-green bg-green-opacity-1 circle-40 font-size-6 mr-7">
                                        <i className="fas fa-user-tie">  </i>

                                    </div>
                                    {/* <!-- Category Content --> */}
                                    <div className="">
                                        <h5 className="font-size-7 text-black-2 line-height-reset font-weight-bold mb-1">

                                            <span>
                                                {(totalERA) ? totalERA : 0}
                                            </span>
                                        </h5>
                                        <p className="font-size-4 font-weight-normal text-gray mb-0">
                                            ERA <br />
                                            <div className="d-flex font-size-4">Expert Ready for Assignment</div>
                                            </p>
                                    </div>
                                   
                                </a>
                                {/* <!-- End Single Category --> */}
                            </div>
                        </div>
                        <div className="mb-10"><h5>Registered Users</h5></div>
                        <div className="row bg-white p-0 m-0 mt-10">
                            <div className="col-12  bg-athens  p-0 m-0">
                                <div className="header-btns ml-5  d-xs-flex ">
                                    <div className="pl-0  " >

                                        <a
                                            className={`btn ${(tabValue == 'total') ? 'btn-primary' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                            href="/#"
                                            onClick={(e) => {
                                                e.preventDefault(); 
                                                setTabValue('total');
                                            }}
                                        >
                                            Total Users
                                        </a>
                                        <a
                                            className={`btn ${(tabValue == 'month') ? 'btn-primary' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                            href="/#"
                                            onClick={(e) => {
                                                e.preventDefault(); 
                                                setTabValue('month');

                                            }}
                                        >
                                            Monthly Users
                                        </a>
                                        <a
                                            className={`btn ${(tabValue == 'verified') ? 'btn-primary' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                            href="/#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setTabValue('verified');

                                            }}
                                        >
                                            Verified  Users
                                        </a>
                                         
                                        <a
                                            className={`btn ${(tabValue == 'era') ? 'btn-primary' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                            href="/#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setTabValue('era');

                                            }}
                                        >
                                           ERA 
                                        </a>




                                    </div>

                                </div>
                            </div>
                        </div>

                        {tabValue == "total" && <div style={{ width: "380",height:"500px", overflowX: "auto", borderRadius: '10px' }} className="border p-10">   <PieChart
                            series={[
                                {
                                    data: userCount
                                },
                            ]} loading={userCount.length == 0}
                            width={350}
                            height={420} slotProps={{
                                legend: {
                                    direction: 'row',
                                    position: { vertical: 'top', horizontal: 'left' },
                                    padding: 0,
                                },
                            }}
                            colors={['#91AE28', '#F7921E']}
                        /></div>}
                        {tabValue == "era" && <div style={{ width: "380", height: "500px", overflowX: "auto", borderRadius: '10px' }} className="border p-10">   <PieChart
                            series={[
                                {
                                    data: userERACount
                                },
                            ]} loading={userERACount.length == 0}
                            width={350}
                            height={420} slotProps={{
                                legend: {
                                    direction: 'row',
                                    position: { vertical: 'top', horizontal: 'left' },
                                    padding: 0,
                                },
                            }}
                            colors={['#91AE28', '#F7921E']}
                        /></div>}
                        {tabValue == "verified" && <div style={{ borderRadius: '10px' }} className=" row border p-10"> <div className="col-lg-6 col-sm-12 col-md-12">
                            <h6> Expert Users</h6>
                            <div style={{ width: "350", height: "450px",   }}  >   <PieChart
                            series={[
                                {
                                    data: euserVerifiedCount
                                },
                            ]} loading={euserVerifiedCount.length == 0}
                            width={350}
                            height={400} slotProps={{
                                legend: {
                                    direction: 'row',
                                    position: { vertical: 'top', horizontal: 'left' },
                                    padding: 0,
                                },
                            }}
                            colors={['#91AE28', '#F7921E']}
                        /></div></div>
                            <div className="col-lg-6 col-sm-12 col-md-12">

                                <h6> Client Users</h6>
                                <div style={{ width: "350", height: "450px",   borderRadius: '10px' }} className="">   <PieChart
                                series={[
                                    {
                                        data: cuserVerifiedCount
                                    },
                                ]} loading={cuserVerifiedCount.length == 0}
                                width={350}
                                height={400} slotProps={{
                                    legend: {
                                        direction: 'row',
                                        position: { vertical: 'top', horizontal: 'left' },
                                        padding: 0,
                                    },
                                }}
                                    colors={['#5877be', '#fa8557']}
                            /></div></div>
                        </div>
                        }
                       
                        {tabValue == "month" && <div style={{ width: "380", height: "600px", overflowX: "auto", borderRadius: '10px' }} className="border p-10">  <BarChart
                            width={1050}
                            height={400} loading={expertData.length == 0}
                            slotProps={{
                                legend: {
                                    direction: 'row',
                                    position: { vertical: 'top', horizontal: 'left' },
                                    padding: 0,

                                },
                            }}
                            series={[
                                { data: expertData, label: 'Expert', id: 'pvId' },
                                { data: clientData, label: 'Client', id: 'uvId' },
                            ]}
                            xAxis={[{ data: xLabels, scaleType: 'band', }]}
                            yAxis={[{ label: "Users Count" }]}
                            colors={['#F7921E', '#91AE28']}
                             
                        /></div>}
                        
                        <div className="mt-10" style={{ height: "50px" }}>&nbsp;</div>
                        <div className="accordion rounded-10 border  pl-1  mt-10">
                        </div>




                    </div>
                </div>
            </PageWrapper>
        </>
    );
};